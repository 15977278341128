<template>
  <div>
      <top title="惠医保服务条款" />
      <div class="box">

      <p style="text-indent:21.0000pt; text-align:justify;padding-top:14px"><span style="font-size:14px"><span style="line-height:150%"><span style="font-family:Calibri"><span style="font-size:12.0000pt"><span ><span style="line-height:150%"><font >欢迎使用惠医保（广州）信息有限公司服务（简称</font>&ldquo;本服务&rdquo;）。本服务条款适用于惠医保旗下产品。</span></span></span></span></span></span></p>

      <p style="text-indent:24.0000pt; text-align:justify"><span style="font-size:14px"><span style="line-height:150%"><span style="font-family:Calibri"><span style="font-size:12.0000pt"><span ><span style="line-height:150%"><font >请用户仔细阅读本服务条款（简称</font></span></span></span><span style="font-size:12.0000pt"><span style="font-family:Calibri"><span style="line-height:150%">&ldquo;</span></span></span><span style="font-size:12.0000pt"><span ><span style="line-height:150%"><font >本条款</font></span></span></span><span style="font-size:12.0000pt"><span style="font-family:Calibri"><span style="line-height:150%">&rdquo;</span></span></span><span style="font-size:12.0000pt"><span ><span style="line-height:150%"><font >）全部内容，特别是粗体、下划线标注的内容。除非用户已阅读并接受本条款全部内容，否则请不要使用本服务。用户对本服务的使用或勾选</font>&ldquo;同意《惠医保服务条款》&rdquo;的行为即视为用户已阅读并同意接受本条款的约束。此后，用户不得以未阅读本条款内容作任何形式的抗辩。未成年人请在法定监护人指导下阅读本条款。</span></span></span></span></span></span></p>

      <p style="text-indent:24.0000pt; text-align:justify"><span style="font-size:14px"><span style="line-height:150%"><span style="font-family:Calibri"><span style="font-size:12.0000pt"><span ><span style="line-height:150%"><font >用户明白并认可：惠医保服务所涉及到的惠医保产品的所有权、知识产权和运作权等一切权益皆归惠医保（广州）信息有限公司（简称</font>&ldquo;惠医保&rdquo;）所有。所提供的服务必须按照其发布的公司章程，服务条款和操作规则等严格执行。</span></span></span></span></span></span></p>

      <p style="text-indent:24.0000pt; text-align:justify"><span style="font-size:14px"><span style="line-height:150%"><span style="font-family:Calibri"><span style="font-size:12.0000pt"><span ><span style="line-height:150%"><font >一、服务说明</font></span></span></span></span></span></span></p>

      <p style="text-indent:24.0000pt; text-align:justify"><span style="font-size:14px"><span style="line-height:150%"><span style="font-family:Calibri"><span style="font-size:12.0000pt"><span ><span style="line-height:150%"><font >惠医保主要向用户提供网上商城、直付药房等服务。惠医保可能会根据不同的功能、用户的需求、服务的变化等，提供和调整本服务的种类及内容，具体以相关服务官方页面公布内容为准，用户可以根据需要自行选择。同时，请用户及时了解本服务内容的变化，用户理解和接受惠医保对本服务进行调整可能会对用户已享有或正在享有的权益造成影响，并同意按照调整后的内容进行使用。</font></span></span></span>&nbsp;</span></span></span></p>

      <p style="text-indent:24.0000pt; text-align:justify"><span style="font-size:14px"><span style="line-height:150%"><span style="font-family:Calibri"><span style="font-size:12.0000pt"><span ><span style="line-height:150%"><font >二、使用规则</font></span></span></span></span></span></span></p>

      <p style="text-indent:24.0000pt; text-align:justify"><span style="font-size:14px"><span style="line-height:150%"><span style="font-family:Calibri"><span style="font-size:12.0000pt"><span style="font-family:Calibri"><span style="line-height:150%">1</span></span></span><span style="font-size:12.0000pt"><span ><span style="line-height:150%"><font >、用户独立承担其使用本服务所产生的责任并遵守所有适用于本服务的法律法规。</font></span></span></span></span></span></span></p>

      <p style="text-indent:24.0000pt; text-align:justify"><span style="font-size:14px"><span style="line-height:150%"><span style="font-family:Calibri"><span style="font-size:12.0000pt"><span ><span style="line-height:150%">2<font >、用户使用本服务时，不得有以下情形：</font></span></span></span></span></span></span></p>

      <p style="text-indent:24.0000pt; text-align:justify"><span style="font-size:14px"><span style="line-height:150%"><span style="font-family:Calibri"><span style="font-size:12.0000pt"><span ><span style="line-height:150%"><font >（</font>1<font >）违反宪法或法律法规规定的；</font></span></span></span></span></span></span></p>

      <p style="text-indent:24.0000pt; text-align:justify"><span style="font-size:14px"><span style="line-height:150%"><span style="font-family:Calibri"><span style="font-size:12.0000pt"><span ><span style="line-height:150%"><font >（</font>2<font >）危害国家安全，泄露国家秘密，颠覆国家政权，破坏国家统一的；</font></span></span></span></span></span></span></p>

      <p style="text-indent:24.0000pt; text-align:justify"><span style="font-size:14px"><span style="line-height:150%"><span style="font-family:Calibri"><span style="font-size:12.0000pt"><span ><span style="line-height:150%"><font >（</font>3<font >）损害国家荣誉和利益的，损害公共利益的；</font></span></span></span></span></span></span></p>

      <p style="text-indent:24.0000pt; text-align:justify"><span style="font-size:14px"><span style="line-height:150%"><span style="font-family:Calibri"><span style="font-size:12.0000pt"><span ><span style="line-height:150%"><font >（</font>4<font >）煽动民族仇恨、民族歧视，破坏民族团结的；</font></span></span></span></span></span></span></p>

      <p style="text-indent:24.0000pt; text-align:justify"><span style="font-size:14px"><span style="line-height:150%"><span style="font-family:Calibri"><span style="font-size:12.0000pt"><span ><span style="line-height:150%"><font >（</font>5<font >）破坏国家宗教政策，宣扬邪教和封建迷信的；</font></span></span></span></span></span></span></p>

      <p style="text-indent:24.0000pt; text-align:justify"><span style="font-size:14px"><span style="line-height:150%"><span style="font-family:Calibri"><span style="font-size:12.0000pt"><span ><span style="line-height:150%"><font >（</font>6<font >）散布谣言，扰乱社会秩序，破坏社会稳定的；</font></span></span></span></span></span></span></p>

      <p style="text-indent:24.0000pt; text-align:justify"><span style="font-size:14px"><span style="line-height:150%"><span style="font-family:Calibri"><span style="font-size:12.0000pt"><span ><span style="line-height:150%"><font >（</font>7<font >）散布淫秽、色情、赌博、暴力、凶杀、恐怖或者教唆犯罪的；</font></span></span></span></span></span></span></p>

      <p style="text-indent:24.0000pt; text-align:justify"><span style="font-size:14px"><span style="line-height:150%"><span style="font-family:Calibri"><span style="font-size:12.0000pt"><span ><span style="line-height:150%"><font >（</font>8<font >）侮辱或者诽谤他人，侵害他人合法权益的；</font></span></span></span></span></span></span></p>

      <p style="text-indent:24.0000pt; text-align:justify"><span style="font-size:14px"><span style="line-height:150%"><span style="font-family:Calibri"><span style="font-size:12.0000pt"><span ><span style="line-height:150%"><font >（</font>9<font >）含有法律、行政法规禁止的其他内容的。</font></span></span></span></span></span></span></p>

      <p style="text-indent:24.0000pt; text-align:justify"><span style="font-size:14px"><span style="line-height:150%"><span style="font-family:Calibri"><span style="font-size:12.0000pt"><span ><span style="line-height:150%">3<font >、用户在使用本服务时，不得损害、禁用、影响惠医保的其他服务或使惠医保的其他服务负担过重，也不得影响任何其它方享用惠医保的其他服务。</font></span></span></span></span></span></span></p>

      <p style="text-indent:24.0000pt; text-align:justify"><span style="font-size:14px"><span style="line-height:150%"><span style="font-family:Calibri"><span style="font-size:12.0000pt"><span style="font-family:Calibri"><span style="line-height:150%">4</span></span></span><span style="font-size:12.0000pt"><span ><span style="line-height:150%"><font >、用户不得利用本服务，对外发送垃圾邮件。</font></span></span></span></span></span></span></p>

      <p style="text-indent:24.0000pt; text-align:justify"><span style="font-size:14px"><span style="line-height:150%"><span style="font-family:Calibri"><span style="font-size:12.0000pt"><span style="font-family:Calibri"><span style="line-height:150%">5</span></span></span><span style="font-size:12.0000pt"><span ><span style="line-height:150%"><font >、用户不得利用本服务从事制作、传播计算机病毒等破坏性程序的危害计算机信息网络安全的行为；</font></span></span></span></span></span></span></p>

      <p style="text-indent:24.0000pt; text-align:justify"><span style="font-size:14px"><span style="line-height:150%"><span style="font-family:Calibri"><span style="font-size:12.0000pt"><span ><span style="line-height:150%"><font >三、服务及条款的修改</font></span></span></span></span></span></span></p>

      <p style="text-indent:24.0000pt; text-align:justify"><span style="font-size:14px"><span style="line-height:150%"><span style="font-family:Calibri"><span style="font-size:12.0000pt"><span style="font-family:Calibri"><span style="line-height:150%">1</span></span></span><span style="font-size:12.0000pt"><span ><span style="line-height:150%"><font >、惠医保有权根据自身经营决策、政府要求或意见、不可抗力等原因中止或终止本服务。如此等情形发生，惠医保有权采取公告等合理形式通知用户，用户无权要求惠医保就此进行赔偿或承担其他责任。但是，惠医保将退还用户已经支付但未消费完毕的服务费用（如有）。</font></span></span></span></span></span></span></p>

      <p style="text-indent:24.0000pt; text-align:justify"><span style="font-size:14px"><span style="line-height:150%"><span style="font-family:Calibri"><span style="font-size:12.0000pt"><span style="font-family:Calibri"><span style="line-height:150%">2</span></span></span><span style="font-size:12.0000pt"><span ><span style="line-height:150%"><font >、惠医保有权在必要时通过在网页上发出公告等合理方式修改本条款。用户在使用本服务时，应当及时查阅了解修改的内容，并自觉遵守本条款。用户如继续使用本服务，则视为对修改内容的同意；用户在不同意修改内容的情况下，应立即停止使用本服务。</font></span></span></span></span></span></span></p>

      <p style="text-indent:24.0000pt; text-align:justify"><span style="font-size:14px"><span style="line-height:150%"><span style="font-family:Calibri"><span style="font-size:12.0000pt"><span style="font-family:Calibri"><span style="line-height:150%">3</span></span></span><span style="font-size:12.0000pt"><span ><span style="line-height:150%"><font >、除法律有明确规定的情况外，惠医保有权不经用户申请并直接为用户办理退款（如有）。</font></span></span></span></span></span></span></p>

      <p style="text-indent:24.0000pt; text-align:justify"><span style="font-size:14px"><span style="line-height:150%"><span style="font-family:Calibri"><span style="font-size:12.0000pt"><span ><span style="line-height:150%"><font >四、责任声明</font></span></span></span></span></span></span></p>

      <p style="text-indent:24.0000pt; text-align:justify"><span style="font-size:14px"><span style="line-height:150%"><span style="font-family:Calibri"><span style="font-size:12.0000pt"><span ><span style="line-height:150%">1<font >、用户在使用本服务时，如果涉及惠医保其他单项服务或惠医保以外的第三方提供的产品或服务的，除遵守本协议约定外，还应遵守其他相关的用户协议</font><font face="Calibri">/</font><font >服务条款。用户通过本服务购买</font><font face="Calibri">/</font><font >接受惠医保以外的第三方提供的商品或服务，如因此发生纠纷的，由用户与该第三方自行解决，惠医保仅提供必要协助。</font></span></span></span></span></span></span></p>

      <p style="text-indent:24.0000pt; text-align:justify"><span style="font-size:14px"><span style="line-height:150%"><span style="font-family:Calibri"><span style="font-size:12.0000pt"><span ><span style="line-height:150%">2<font >、用户须对自身使用本服务时所实施的一切行为承担全部法律责任。</font></span></span></span></span></span></span></p>

      <p style="text-indent:24.0000pt; text-align:justify"><span style="font-size:14px"><span style="line-height:150%"><span style="font-family:Calibri"><span style="font-size:12.0000pt"><span ><span style="line-height:150%">3<font >、惠医保不对用户因机房维护、设备检修、系统升级或优化造成的损失、第三方的行为或不作为造成的损失、不可抗力原因造成的损失承担任何责任，包括但不限于：支付服务、网络接入服务、电信部门的通讯线路故障、通讯技术问题、网络、电脑故障、系统不稳定性、任意第三方的侵权行为等。</font></span></span></span></span></span></span></p>

      <p style="text-indent:24.0000pt; text-align:justify"><span style="font-size:14px"><span style="line-height:150%"><span style="font-family:Calibri"><span style="font-size:12.0000pt"><span ><span style="line-height:150%">4<font >、用户需自行承担由于网络信号不稳定、网络带宽小、网络拥堵等网络问题以及系统或设备故障等原因，所引起的本服务失败、信息同步不完整、页面打开速度慢等风险。</font></span></span></span></span></span></span></p>

      <p style="text-indent:24.0000pt; text-align:justify"><span style="font-size:14px"><span style="line-height:150%"><span style="font-family:Calibri"><span style="font-size:12.0000pt"><span ><span style="line-height:150%">5<font >、除非由于惠医保原因导致，否则惠医保将不承担任何退还已收取费用的义务。</font></span></span></span></span></span></span></p>

      <p style="text-indent:24.0000pt; text-align:justify"><span style="font-size:14px"><span style="line-height:150%"><span style="font-family:Calibri"><span style="font-size:12.0000pt"><span ><span style="line-height:150%"><font >五、违约责任</font></span></span></span></span></span></span></p>

      <p style="text-indent:24.0000pt; text-align:justify"><span style="font-size:14px"><span style="line-height:150%"><span style="font-family:Calibri"><span style="font-size:12.0000pt"><span ><span style="line-height:150%">1<font >、</font><font face="Calibri">&nbsp;</font><font >用户对本服务的使用必须遵守所有适用于本服务的可适用法律，用户在使用本服务中的过程中不得侵犯任何人合法权益，不得违反本条款、惠医保已经发布的或将来可能发布</font><font face="Calibri">/</font><font >更新的各类规则以及法律法规中的相关规定；不得进行任何非法活动。否则由此产生的一切责任由用户独立承担，如因此造成惠医保损失的，用户应负责全部赔偿。同时，惠医保将根据国家法律法规、相关政策在必要时向有关机关报告并配合有关机关的行动。惠医保保留独立判断用户的行为是否符合本条款的要求的权利，如果用户违背了本条款的规定，惠医保有权采取处罚措施，包括但不限于在不通知用户的情况下采取暂停、中止、终止本服务等措施，并无需退还该用户已付的任何费用。</font></span></span></span></span></span></span></p>

      <p style="text-indent:24.0000pt; text-align:justify"><span style="font-size:14px"><span style="line-height:150%"><span style="font-family:Calibri"><span style="font-size:12.0000pt"><span ><span style="line-height:150%">2<font >、</font></span></span></span>&nbsp;<span style="font-size:12.0000pt"><span ><span style="line-height:150%"><font >无论发生何种情况，惠医保在提供本服务过程中因其过错给用户带来损失的，惠医保向用户支付的赔偿总额不应超过用户向惠医保支付的服务费用。</font></span></span></span></span></span></span></p>

      <p style="text-indent:24.0000pt; text-align:justify"><span style="font-size:14px"><span style="line-height:150%"><span style="font-family:Calibri"><span style="font-size:12.0000pt"><span ><span style="line-height:150%"><font >六、隐私制度</font></span></span></span></span></span></span></p>

      <p style="text-indent:24.0000pt; text-align:justify"><span style="font-size:14px"><span style="line-height:150%"><span style="font-family:Calibri"><span style="font-size:12.0000pt"><span ><span style="line-height:150%"><font >尊重用户隐私是惠医保的一项基本政策。惠医保将按照《惠医保隐私政策》收集、存储、使用、披露和保护您的个人信息。请您完整阅读上述隐私权政策，以帮助您更好地保护您的个人信息。</font></span></span></span></span></span></span></p>

      <p style="text-indent:24.0000pt; text-align:justify"><span style="font-size:14px"><span style="line-height:150%"><span style="font-family:Calibri"><span style="font-size:12.0000pt"><span ><span style="line-height:150%"><font >七、法律与争议解决</font></span></span></span></span></span></span></p>

      <p style="text-indent:24.0000pt; text-align:justify"><span style="font-size:14px"><span style="line-height:150%"><span style="font-family:Calibri"><span style="font-size:12.0000pt"><span ><span style="line-height:150%"><font >本条款适用中华人民共和国的法律，并且排除一切冲突法规定的适用。如出现纠纷，用户和惠医保一致同意将纠纷提交</font></span></span></span><span style="font-size:12.0000pt"><span ><span style="line-height:150%"><font >广州</font></span></span></span><span style="font-size:12.0000pt"><span ><span style="line-height:150%"><font >仲裁委员会根据其现行有效的仲裁规则进行仲裁。仲裁费用由败诉一方承担。</font></span></span></span></span></span></span></p>

      <p style="text-indent:24.0000pt; text-align:justify"><span style="font-size:14px"><span style="line-height:150%"><span style="font-family:Calibri"><span style="font-size:12.0000pt"><span ><span style="line-height:150%"><font >八、权利声明</font></span></span></span></span></span></span></p>

      <p style="text-indent:24.0000pt; text-align:justify"><span style="font-size:14px"><span style="line-height:150%"><span style="font-family:Calibri"><span style="font-size:12.0000pt"><span ><span style="line-height:150%"><font >惠医保不行使、未能及时行使或者未充分行使本条款或者按照法律规定所享有的权利，不应被视为放弃该权利，也不影响惠医保在将来行使该权利。</font></span></span></span></span></span></span></p>

      <p style="text-align:justify">&nbsp;</p>

  </div>
  </div>
</template>

<script>
export default {
};
</script>

<style scoped lang="scss">
.box{
    padding: 0 vw(28) vw(24);
    line-height: vw(44);
    p{
        line-height: vw(44);
    }
}
</style>